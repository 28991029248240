.BulletPoint-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;
    height: 60px;
}

.BulletPointText {
    margin: 0 30px;
    display: flex;
    text-align: left;
}


@media only screen and (max-width: 700px) {
    .BulletPointText {
        font-size: 20px;
        margin-left: 30px;
        margin-right: 30px;

    }
}

@media only screen and (max-width: 500px) {
    .BulletPointText {
        font-size: 16px;
    }
}

.BulletPoint {
    background-color: #61dafb;
    border: 2px solid #282c34;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
}