.Inner-Container10 {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: flex-start;
    border-left: 5px solid #61dafb;
    padding-left: 20px;
    margin-bottom: -15px;
}

.Inner-Container10 ul li {
    margin-bottom: 5px;
    margin-left: -15px;
}