.Code-Container-Up {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #1f2228;
    border-radius: 5px;
    padding: 10px;
}

    @media only screen and (max-width: 500px) {
        .Code-Container-Up {
            font-size: 12px;
        }

        .Code-Container-Down {
            font-size: 12px;
        }
    }


.Code-Container-Down {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #1f2228;
    border-radius: 5px;
    padding: 10px;
}