.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -300px;


}

.Logo-Stil {
    width: 700px;
    height: 700px;
}

.Outer-Component-Img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    margin-top: 100px;
    margin-bottom: 100px;
}

.React-Logo {

    margin-top: -260px;
    margin-left: 5px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;

    padding: 0;
}



.React-Logo-Header {
    font-weight: 1000;
    font-size: 60px;
    padding: 0;
    background: rgba(31, 34, 40, 0.38);
    border-radius: 10px;

}

.AllBulletPointContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;

    margin-block-start: -220px;
}

.BulletPoint-Container1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 500px;

}

.BulletPoint-Container2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 500px;

}
