.HistoryContainer {
    width: 100%;
    display: flex;
    margin-left: 35px;

}

@media only screen and (max-width: 700px) {
    .HistoryContainer{
        width: 90vw;
    }

    .HistoryHeader {
        background: rgba(40, 44, 52, 0.89);
    }
}

.HistoryHeader {
    padding: 2px;
    position: relative;
    z-index: 100;
    margin: -150px 0 130px 0;
}