.Last-Outer-Component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
}

.Last-Outer-Component-Img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    margin-top: 100px;
    margin-bottom: 100px;
}

@media only screen and (max-width: 700px) {
    .Last-Outer-Component-Img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 50px;
    }
}

.Extra {
    margin-top: -225px;
}