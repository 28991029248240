.App-header-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.App-header-text {
    font-weight: 10000;
    font-size: 120px;

}

@media only screen and (max-width: 1200px) {
    .App-header-inner {
        margin-top: -100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .App-header-text {
        font-weight: 10000;
        font-size: 80px;
        align-items: center;
        display: flex;
        margin-top: -110px;
    }
}

@media only screen and (max-width: 600px) {
    .App-header-text {
        font-weight: 10000;
        font-size: 60px;
        align-items: center;
        display: flex;
        margin-top: -5%;
    }
}