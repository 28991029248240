.JSX-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
}

.JSX-Container h5 {
    margin-top: 0;
}

.JSX-h2 {
    margin-bottom: -15px;
}