.Outer-Container {
    margin-top: -150px;
    max-width: 80%;
}

.Inner-Container5 {
    display: flex;
    flex-direction: row;
    width: 80vw;
}

.Left-Container {
    margin-right: 30px;
    text-align: left;
}

.Line {
    border: 3px solid #61dafb;
}

.Right-Container {
    margin-left: 18px;
    text-align: left;
}

.VDOM-header {
    font-size: 38px;
}

.VDOM-List-Element {
    font-size: 26px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 1000px) {
    .Inner-Container5 {
        display: flex;
        flex-direction: column;
    }

    .Left-Container {
        margin: 0;
        margin-bottom: 10px;
    }

    .Right-Container {
        margin: 0;
        margin-top: 10px;
    }

    .VDOM-header {
        font-size: 24px;
    }

    .VDOM-List-Element {
        font-size: 20px;
    }
}

@media only screen and (max-width: 400px) {
    .VDOM-List-Element {
        font-size: 16px;
    }
}
