.Most-Outer-BulletPoint-Container {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    margin-top: -100px;
    margin-left: 20px;
}


.Outer-BulletPoint-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: -8.25px;
    z-index: 100;
}


.Big-Line {
    width: 7px; /* Breite der Linie */
    height: 100vh; /* Die volle Höhe des Bildschirms */
    background-color: #61dafb; /* Hellblaue Farbe */
    position: fixed; /* Position fixieren, um das Scrollen zu berücksichtigen */
    top: 0; /* Von oben beginnen */

}