.Container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header {
    margin: 10px;
    margin-top: -150px;
    margin-bottom: 30px;
}

.Outer-Code-Container {
    display: flex;
    justify-content: center;
}

.Code-container {
    background-color: #1f2228;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Code-Header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1000px) {
    .Code-container {
        font-size: 20px;
    }

    .Code-Header {
        font-size: 24px;
    }
}

@media only screen and (max-width: 550px) {
    .Code-container {
        font-size: 16px;
    }

    .Code-Header {
        font-size: 20px;
    }
}